import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import cookie from "js-cookie"
import * as custom from './util/utils'
import { Lazyload } from 'vant';
Vue.use(Lazyload);
Vue.prototype.Cook = cookie
Vue.prototype.axios = axios
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})
Vue.use(Vant);
Vue.config.productionTip = false
axios.interceptors.request.use(config => {
  let token = sessionStorage.getItem('accessToken')
  if (sessionStorage.getItem('accessToken')) {
    token = token.replace(/'|"/g, '')
    config.headers.Authorization = token;
  }
  return config;
},
  error => {
    return Promise.reject(error);
  });
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
axios.interceptors.response.use(response => {
  if (response.data.code === 10005 || response.data.code === 10006) {
    alert('登录已失效')
    sessionStorage.removeItem('accessToken'); ('token')

  } else if (response.data.token) {
    sessionStorage.getItem('accessToken', response.data.token)
  }
  if (response.status == 200) {
    return response;
  }
}, function (error) {
  return Promise.reject(error)
})
Vue.use(Lazyload, {
  lazyComponent: true,
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')