import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/listTemplate',
    name: 'listTemplate',
    component: () => import('../views/listTemplate.vue'),
    meta: {
      title: '视频会员',
      keepAlive: false
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },
  {
    path: '/orderTemplate',
    name: 'orderTemplate',
    component: () => import('../views/orderTemplate.vue'),
    meta: {
      title: '我的订单',
      keepAlive: false
    }
  },
  {
    path: '/goodslist',
    name: 'goodlsist',
    component: () => import('../views/moudels/list/goodslist.vue'),
    meta: {
      title: '视频会员',
      keepAlive: false
    }
  },
  {
    path: '/checkOrder',
    name: 'checkOrder',
    component: () => import('../views/moudels/order/checkOrder.vue'),
    meta: {
      title: '会员充值订单',
      keepAlive: false
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/moudels/order/order.vue'),
    meta: {
      title: '会员充值订单',
      keepAlive: false
    }
  },
  {
    path: '/order_w',
    name: 'order_w',
    component: () => import('../views/moudels/order/order_w.vue'),
    meta: {
      title: '会员充值订单',
      keepAlive: false
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge.vue'),
    meta: {
      title: '会员充值',
      keepAlive: false
    }
  },
  {
    path: '/signergys',
    name: 'signergys',
    component: () => import('../views/shangjie/signergys.vue'),
    meta: {
      title: '恭喜获得',
      keepAlive: false
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/service.vue'),
    meta: {
      title: '客服',
      keepAlive: false
    }
  }
  ,
  {
    path: '/h5_details',
    name: 'h5_details',
    component: () => import('../views/moudels/pay/h5_details.vue'),
    meta: {
      title: '商品详情',
      keepAlive: true
    }
  },
  {
    path: '/h5_recharge',
    name: 'h5_recharge',
    component: () => import('../views/moudels/recharge/h5_recharge.vue'),
    meta: {
      title: '商品详情',
      keepAlive: true
    }
  },
  {
    path: '/weChat_recharge',
    name: 'weChat_recharge',
    component: () => import('../views/moudels/recharge/weChat_recharge.vue'),
    meta: {
      title: '商品详情',
      keepAlive: true
    }
  },
  {
    path: '/wechatPay',
    name: 'wechatPay',
    component: () => import('../views/moudels/pay/wechatPay.vue'),
    meta: {
      title: '商品详情',
      keepAlive: true
    }
  },
  {
    path: '/queryorder',
    name: 'queryorder',
    component: () => import('../views/queryorder.vue'),
    meta: {
      title: '充值订单查询',
      keepAlive: false
    }
  },
  {
    path: '/queryorder',
    name: 'queryorder',
    component: () => import('../views/queryorder.vue'),
    meta: {
      title: '充值订单查询',
      keepAlive: false
    }
  },
  {
    path: '/couponPay',
    name: 'couponPay',
    component: () => import('../views/moudels/pay/couponPay.vue'),
    meta: {
      title: '商品详情',
      keepAlive: false
    }
  },
  {
    path: '/couponPage',
    name: 'CouponPage',
    component: () => import('../views/moudels/recharge/couponPage.vue'),
    meta: {
      title: '优惠券',
      keepAlive: false
    }
  },
]
const router = new VueRouter({
  // mode: 'history',
  base: process.env.VUE_APP_PATH,
  routes
})
export default router